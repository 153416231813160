import React, { useState, useEffect, useCallback } from "react";
import 'react-toastify/dist/ReactToastify.css';
import Iicon from "../../assects/images/i.png";
import Loinp from "../../assects/images/dtokens.png";
import eth from "../../assects/svgs/ethsv.svg";
import USDT from "../../assects/images/USDT.png";
import BNB from "../../assects/images/loinppre (2).png";
import downarw from "../../assects/svgs/droparw.svg";
import downeth from "../../assects/svgs/dropeth.svg";
import Solid from "../../assects/images/logo-white-3.png.png";
import thumb from "../../assects/images/thumb.webp";
import poster from "../../assects/images/vedio.png";
import que from "../../assects/svgs/questionIcon.svg";
import refe from "../../assects/svgs/referralIcon.svg";
import Link1 from "../../assects/svgs/ftsvg (1).svg";
import Link2 from "../../assects/svgs/ftsvg (2).svg";
import Link3 from "../../assects/svgs/ftsvg (3).svg";
import Link4 from "../../assects/svgs/ftsvg (4).svg";
import sharelogo from "../../assects/images/shareicon.png";
// import vids from "../../assects/video/vid.mp4";

import '@rainbow-me/rainbowkit/styles.css';

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

import { bsc } from 'wagmi/chains'
import { useWriteContract, useAccount, useBalance } from 'wagmi'
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi'
import { useSwitchChain } from 'wagmi'
import {
    useConnectModal,
    useChainModal,
} from '@rainbow-me/rainbowkit';

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { parseEther } from 'viem'

import { useTranslation } from 'react-i18next';


// const stakeAndClaimContract = "0x255b82eC452E00e3E9D2c56441AB133B616704F8";
// const dreamcarsAddress = "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10";

const abi = [
    {
        name: "getAmountsOut",
        type: "function",
        inputs: [
            {
                name: "amountIn",
                type: "uint256",
            },
            { name: "path", type: "address[]" },
        ],
        outputs: [{ name: "amounts", type: "uint256[]" }],
    },
];


const tokenAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];



const options = [
    { id: 1, label: "ETH", image: eth },
    { id: 2, label: "USDT", image: downeth },
    { id: 3, label: "  BNB", image: BNB }
];

const ProductDetailsSection = ({ range, handleRange, handleClose, setPerc, setParticipants }) => {


    const [isOPen, setIsOPen] = useState(false);
    const [isOPenSpin, setIsOPenSpin] = useState(false);
    const [pop2, setPop2] = useState(false);
    const [pop1, setPop1] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[1]);
    const [showDropdown, setShowDropdown] = useState(false);
    const { t, i18n } = useTranslation();

    const [activeButton, setActiveButton] = useState(null);

    const buttons = [
        { id: 1, label: 'ETH', imgSrc: eth },
        { id: 2, label: 'USDT', imgSrc: USDT },
        { id: 3, label: 'BNB', imgSrc: BNB }
    ];

    const handleClickButton = (id) => {
        handleTokenChange(id)
        setActiveButton(id);
        setSelectedOption(options[id - 1]);
    };

    const handleButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        handleTokenChange(option.id)
        handleClickButton(option.id)
        setShowDropdown(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText("https://dreamcars.co/")
            .then(() => {
                let link = window.location.origin + "/?ref=" + userAddress + "&lang=" + i18n.language
                navigator.clipboard.writeText(link);
                toast.success('Copied!');
            })
            .catch((error) => {
                console.error('Error copying:', error);
                toast.error('Failed to copy.');
            });
    };
    const handleClick = () => {
        setIsOPen(!isOPen);
    };
    const handleClickSpin = () => {
        setIsOPenSpin(!isOPenSpin);
    };

    const handelBonus = () => {
        setShow(!show);
    };

    function copyUrlToClipboard() {
        const url = "https://dreamcars.co/";

        // Use the Clipboard API to copy the URL
        navigator.clipboard.writeText(url)
            .then(() => {
                toast.success("URL copied to clipboard!");
            })
            .catch((err) => {
                toast.error("Failed to copy the URL");
                console.error("Failed to copy the URL", err);
            });
    }


    const { chains, switchChain } = useSwitchChain()
    const { isConnected } = useAccount()
    const [currentChain, setCurrentChain] = useState('BSC');
    const [tokenChoice, setTokenChoice] = useState('USDT');
    const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000');
    const [ethPrice, setEthPrice] = useState(1);
    const [bnbPrice, setBNBPrice] = useState(1);
    const [currentDcarsPrice, setCurrentScorpPrice] = useState(95.23809523809524);
    const [percentage, setPercentage] = useState(0);
    const [currentAllowance, setCurrentAllowance] = useState(0);
    const [currentAllowanceETH, setCurrentAllowanceETH] = useState(0);
    const [totalContributions, setTotalContributions] = useState(0);
    const [totalParticipants, setTotalParticipants] = useState(0);
    const [userBalanceBNB, setUserBalanceBNB] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const { openConnectModal } = useConnectModal();
    const { openChainModal } = useChainModal();
    const [userAmount, setUserAmount] = useState(1000);
    const [userUSD, setUserUSD] = useState(95238.09523809524);
    const [userAddress, setUserAddress] = useState(0);
    const [hasBonus, setHasBonus] = useState(false);
    const [txHash, setTxHash] = useState("");
    const [txHashETH, setTxHashETH] = useState("");
    const [buynowbtn, setBuyNowBtn] = useState(t("BUY $DCARS"));
    const [typeOfTx, setTypeOfTx] = useState("Authorize");

    // Function to handle the deposit event and trigger tracking events
    const handleDeposit = (amount) => {
        // Trigger Facebook Pixel purchase event
        window.fbq("track", "Purchase", { value: amount, currency: "USD" });

        // Trigger Google Analytics purchase event
        window.gtag("event", "purchase", { value: amount, currency: "USD" });
    };

    const { data: userTokensETH, isSuccess: successUserTokensETH } = useReadContract({
        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 1,
        args: [userAddress],
    })

    const { data: userTokens, isSuccess: successUserTokens } = useReadContract({
        address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 56,
        args: [userAddress],
    })


    const [finalUserTokens, setFinalUserTokens] = useState(0)
    const [coupon, setCoupon] = useState("0");
    const { data: totalParticipantETH, refetch: refreshParticipantsETH } = useReadContract({
        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
        abi: [{
            "inputs": [],
            "name": "getTotalParticipants",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalParticipants',
        chainId: 1
    })
    const { data: totalContributionETH, refetch: refreshContributorsETH, isFetched: isContriError } = useReadContract({
        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
        abi: [{
            "inputs": [],
            "name": "getTotalContribution",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalContribution',
        chainId: 1
    })
    const { data: totalParticipant, refetch: refreshParticipants } = useReadContract({
        address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
        abi: [{
            "inputs": [],
            "name": "getTotalParticipants",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalParticipants',
        chainId: 56
    })
    const { data: totalContribution, refetch: refreshContributors, isFetched: isContriError2 } = useReadContract({
        address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
        abi: [{
            "inputs": [],
            "name": "getTotalContribution",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalContribution',
        chainId: 56
    })
    const { data: allowance, isSuccess: successAllowance, refetch: allowanceRefetch } = useReadContract({
        address: '0x55d398326f99059fF775485246999027B3197955',
        abi: [{
            "constant": true,
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'allowance',
        chainId: 56,
        args: [userAddress, '0x883178a1571094a6656cca084f3ed5a3192abaf3'],
    })
    const { data: allowanceETH, isSuccess: successAllowanceETH, refetch: allowanceETHRefetch } = useReadContract({
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        abi: [{
            "constant": true,
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'allowance',
        chainId: 1,
        args: [userAddress, '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba'],
    })

    const { data: bnbData, writeContract: buyUsingBNB, error: bnbErrors, isError: bnbError, isSuccess: bnbSuccess } = useWriteContract()
    const { data: usdtData, writeContract: buyUsingUSDT, isError: usdtError, isSuccess: usdtSuccess } = useWriteContract()
    const { data: allowanceData, writeContract: allowanceUSDT, error: allowanceUSDTerror, isSuccess: allowanceSuccess } = useWriteContract()

    const { data: ethData, writeContract: buyUsingETH, isError: ETHError, isSuccess: ETHSuccess } = useWriteContract()
    const { data: usdtethData, writeContract: buyUsingUSDTETH, isError: usdtEthError, isSuccess: usdtETHSuccess } = useWriteContract()
    const { data: allowanceETHData, writeContract: allowanceUSDTETH, isError: allowanceUSDTETHError, isSuccess: allowanceSuccessETH } = useWriteContract()

    const { data: bnbBalance, status } = useBalance({
        address: userAddress,
        chainId: 56
    })
    const { data: ethBalance, status: ethStatus } = useBalance({
        address: userAddress,
        chainId: 1
    })
    const couponCodes = ["dcars50", "dc50", "dream50", "car50", "cars50", "bf100", "bf50"]
    const handleCoupon = (e) => {
        e.preventDefault();

        var arraycontainsturtles = (couponCodes.indexOf(coupon.toLowerCase()) > -1);

        if (arraycontainsturtles) {
            toast.success("The bonus is applied!", {
                position: "bottom-right"
            })
        }
        else
            toast.error("Code not valid.", {
                position: "bottom-right"
            })
    }
    function getURLParams() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const result = {};
        for (const [key, value] of params.entries()) {
            result[key] = value;
        }

        return result;
    }
    const handleUserInput = async (e) => {
        let correctedInput = e.target.value.replace(/,/g, '.');
        if (!isNaN(correctedInput)) {
            if (correctedInput) {
                setUserAmount(correctedInput)
                if (tokenChoice === "ETH") {
                    setUserUSD(correctedInput * await getEthPrice() * currentDcarsPrice)
                    setBuyNowBtn(t("BUY $DCARS"))
                }
                else if (tokenChoice === "BNB") {
                    setUserUSD(correctedInput * await getBNBPrice() * currentDcarsPrice)
                    setBuyNowBtn(t("BUY $DCARS"))
                } else {
                    if (currentAllowance + currentAllowanceETH < correctedInput * 1e18)
                        setBuyNowBtn(t("Approve transaction"))
                    else
                        setBuyNowBtn(t("BUY $DCARS"))
                    setUserUSD(correctedInput * currentDcarsPrice)
                }
            }
            else {
                setUserAmount(0)
                setUserUSD(0)
            }
        }
    }

    const handleDcarsInput = async (e) => {
        let correctedInput = e.target.value.replace(/,/g, '.');

        if (!isNaN(correctedInput)) {
            if (correctedInput) {
                setUserUSD(correctedInput)
                if (tokenChoice === "ETH") {
                    setUserAmount(((correctedInput / await getEthPrice()) / currentDcarsPrice).toFixed(8))
                }
                else if (tokenChoice === "BNB") {
                    setUserAmount(((correctedInput / await getBNBPrice()) / currentDcarsPrice).toFixed(8))
                } else {
                    setUserAmount(correctedInput / currentDcarsPrice)
                }
            }
            else {
                setUserAmount(0)
                setUserUSD(0)
            }
        }
    }


    const buyToken = async () => {
        if (userAmount > 0) {
            if (tokenChoice === 'USDT') {
                if (currentChain === "Ethereum") {
                    let aer = await allowanceETHRefetch()
                    setCurrentAllowanceETH(Number(aer?.data))

                    if (Number(aer?.data) === 0) {
                        setBuyNowBtn(t("Approve Transaction"))
                        setTypeOfTx("Authorize for USDT")
                        setLoadingStatus(true)
                        allowanceUSDTETH({
                            address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
                            abi: [{ "constant": false, "inputs": [{ "name": "_spender", "type": "address" }, { "name": "_value", "type": "uint256" }], "name": "approve", "outputs": [], "payable": false, "stateMutability": "nonpayable", "type": "function" }],
                            functionName: 'approve',
                            chainId: 1,
                            args: ["0x076b20436b7cf3d461b5b2260a9eeee1967b1dba", userAmount * 1e6],
                            gas: 300000
                        })
                    }
                    else {
                        if (userAmount * 1e6 != Number(aer?.data))
                            toast.info("You have initially approved: " + Number(aer?.data) / 1e6 + " USDT, please send it first.")
                        else {
                            setLoadingStatus(true)
                            toast.info("Confirm the transaction")
                            buyUsingUSDTETH({
                                address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
                                abi: [{
                                    "inputs": [
                                        {
                                            "internalType": "uint256",
                                            "name": "amountInUSD",
                                            "type": "uint256"
                                        },
                                        {
                                            "internalType": "string",
                                            "name": "code",
                                            "type": "string"
                                        },
                                        {
                                            "internalType": "address",
                                            "name": "_referredBy",
                                            "type": "address"
                                        }
                                    ],
                                    "name": "contribute",
                                    "outputs": [],
                                    "stateMutability": "payable",
                                    "type": "function"
                                }],
                                functionName: 'contribute',
                                chainId: 1,
                                args: [userAmount * 1e6, coupon, referral],
                            })
                            setTypeOfTx("Confirm the transaction")
                            setBuyNowBtn(t("BUY $DCARS"))
                        }
                    }
                }
                else if (currentChain === "BSC") {
                    let ar = await allowanceRefetch()
                    setCurrentAllowance(Number(ar?.data))
                    console.log("test")
                    toast.info("Confirm the transaction")
                    if (Number(ar?.data) < userAmount * 1e18) {
                        setLoadingStatus(true)
                        allowanceUSDT({
                            address: '0x55d398326f99059fF775485246999027B3197955',
                            abi: [{
                                "constant": false,
                                "inputs": [
                                    {
                                        "internalType": "address",
                                        "name": "spender",
                                        "type": "address"
                                    },
                                    {
                                        "internalType": "uint256",
                                        "name": "amount",
                                        "type": "uint256"
                                    }
                                ],
                                "name": "approve",
                                "outputs": [
                                    {
                                        "internalType": "bool",
                                        "name": "",
                                        "type": "bool"
                                    }
                                ],
                                "payable": false,
                                "stateMutability": "nonpayable",
                                "type": "function"
                            }],
                            functionName: 'approve',
                            chainId: bsc.id,
                            gasPrice: 3000000000,
                            gas: 200000,

                            args: ["0x883178a1571094a6656cca084f3ed5a3192abaf3", parseEther(userAmount.toString())],
                        })
                        setTypeOfTx("Authorize for USDT")
                        setBuyNowBtn(t("Approve Transaction"))
                    }
                    else {
                        setLoadingStatus(true)
                        toast.info("Confirm the transaction")
                        buyUsingUSDT({
                            address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
                            abi: [{
                                "inputs": [
                                    {
                                        "internalType": "uint256",
                                        "name": "amountInUSD",
                                        "type": "uint256"
                                    },
                                    {
                                        "internalType": "string",
                                        "name": "code",
                                        "type": "string"
                                    },
                                    {
                                        "internalType": "address",
                                        "name": "_referredBy",
                                        "type": "address"
                                    }
                                ],
                                "name": "contribute",
                                "outputs": [],
                                "stateMutability": "payable",
                                "type": "function"
                            }],
                            functionName: 'contribute',
                            chainId: bsc.id,
                            gasPrice: 3000000000,
                            gas: 300000,
                            args: [parseEther(userAmount.toString()), coupon, referral],
                        })
                        setTypeOfTx("Confirm the transaction")
                        setBuyNowBtn(t("BUY $DCARS"))
                    }
                }
            }
            else if (tokenChoice === 'BNB') {
                console.log("touched")
                let bnbBal = bnbBalance
                try {

                    if (Number(bnbBal?.formatted) > Number(userAmount)) {
                        setLoadingStatus(true)
                        setTypeOfTx("Confirm the transaction")
                        toast.info("Confirm the transaction")
                        buyUsingBNB({
                            address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
                            abi: [{
                                "inputs": [
                                    {
                                        "internalType": "uint256",
                                        "name": "amountInUSD",
                                        "type": "uint256"
                                    },
                                    {
                                        "internalType": "string",
                                        "name": "code",
                                        "type": "string"
                                    },
                                    {
                                        "internalType": "address",
                                        "name": "_referredBy",
                                        "type": "address"
                                    }
                                ],
                                "name": "contribute",
                                "outputs": [],
                                "stateMutability": "payable",
                                "type": "function"
                            }],
                            functionName: 'contribute',
                            chainId: bsc.id,
                            args: [0, coupon, referral],
                            value: (parseEther(userAmount.toString())),
                            gasPrice: 3000000000,
                            gas: 350000

                        })

                        handleDeposit(userUSD); // Call the tracking function with the deposit amount
                    }
                    else
                        toast.error("Please check if you have enough funds and try again.")

                } catch (error) {
                    console.log(error)
                }
            }
            else if (tokenChoice === 'ETH') {
                let ethBal = ethBalance
                if (Number(ethBal?.formatted) > Number(userAmount)) {
                    setLoadingStatus(true)
                    setTypeOfTx("Confirm the transaction")
                    toast.info("Confirm the transaction")
                    buyUsingETH({
                        address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
                        abi: [{
                            "inputs": [
                                {
                                    "internalType": "uint256",
                                    "name": "amountInUSD",
                                    "type": "uint256"
                                },
                                {
                                    "internalType": "string",
                                    "name": "code",
                                    "type": "string"
                                },
                                {
                                    "internalType": "address",
                                    "name": "_referredBy",
                                    "type": "address"
                                }
                            ],
                            "name": "contribute",
                            "outputs": [],
                            "stateMutability": "payable",
                            "type": "function"
                        }],
                        functionName: 'contribute',
                        chainId: 1,
                        args: [0, coupon, referral],

                        value: parseEther(userAmount.toString()),
                        gas: 300000
                    })
                }
                else
                    toast.error("Please check if you have enough funds and try again.")
            }
        }
    }

    const handleTokenChange = async (token) => {
        if (isConnected) {
            if (token === 1) {
                if (currentChain !== 'Ethereum') {
                    switchChain({ chainId: 1 })
                    setTokenChoice('ETH')
                } else {
                    setTokenChoice('ETH')
                    setBuyNowBtn(t("BUY $DCARS"))
                    if (Number(ethBalance?.formatted).toFixed(6) - 0.005 > 0) {
                        setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                        setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)
                    }
                    else {
                        setUserAmount(1)
                        setUserUSD(await getEthPrice() * currentDcarsPrice)
                    }

                }
            }
            else if (token === 2) {
                setTokenChoice('USDT')
                if (currentAllowance + currentAllowanceETH < userAmount * 1e18)
                    setBuyNowBtn(t("Approve transaction"))
                openChainModal?.();
                // setUserUSD(userAmount * currentDcarsPrice)

                setUserAmount(1000)
                setUserUSD(1000 * currentDcarsPrice)

            }
            else {
                if (currentChain !== 'BSC') {
                    switchChain({ chainId: 56 })
                    setTokenChoice('BNB')
                } else {
                    setTokenChoice('BNB')
                    setBuyNowBtn(t("BUY $DCARS"))
                    if (Number(bnbBalance?.formatted).toFixed(6) - 0.0015 > 0) {
                        setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                        setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                    }

                    else {
                        setUserAmount(1)
                        setUserUSD(await getBNBPrice() * currentDcarsPrice)
                    }
                }
            }
        }
        else
            openConnectModal?.();
    }

    const { data } = useWaitForTransactionReceipt({
        confirmations: 3,
        hash: txHash,
    })

    const { data: dataETH } = useWaitForTransactionReceipt({
        confirmations: 3,
        hash: txHashETH,
    })

    const handleShare = async () => {
        // Check if Web Share API is supported
        if (navigator.share) {
          try {
            await navigator.share({
              url: window.location.href, // You can customize this URL
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Web Share API is not supported in your browser.');
        }
      };
    useEffect(() => {
        setFinalUserTokens(Number(userTokens) / 1e18 + Number(userTokensETH) / 1e18)

    }, [successUserTokens, successUserTokensETH])

    useEffect(() => {
        handleRange(1000)
    }, [])
    useEffect(() => {
        (async () => {
            if (tokenChoice === "USDT") {
                handleRange(userAmount)

            } else
                if (tokenChoice === "ETH") {
                    handleRange(userAmount * await getEthPrice())
                }
        }
        )()
    }, [userAmount])

    useEffect(() => {
        (async () => {
            if (data) {
                toast.success("Transaction was successful 🎉");
                let con = await refreshContributors();
                let conETH = await refreshContributorsETH();
                let par = await refreshParticipants();
                let parETH = await refreshParticipantsETH();
                let x = Number(con?.data) / 1000000
                let y = Number(conETH?.data) / 1000000
                setTotalContributions(x + y + 257454)
                if (x + y < 0)
                    setPercentage(((x + y + 257454) / 500000 * 100))
                else
                    setPercentage(((x + y + 257454) / 500000 * 100))
                setTotalParticipants(Number(par?.data) + Number(parETH?.data) + 279)
                setParticipants(Number(par?.data) + Number(parETH?.data) + 279)
                setLoadingStatus(false)
            }
        }
        )()
    }, [data])

    useEffect(() => {
        (async () => {
            if (dataETH) {
                toast.success("Approval was successful 🎉");
                setLoadingStatus(false)
                setBuyNowBtn(t("BUY $DCARS"))
            }
        }
        )()
    }, [dataETH])
    useEffect(() => {
        if (allowanceETHData)
            setTxHashETH(allowanceETHData)
        if (allowanceData)
            setTxHashETH(allowanceData)
    }, [allowanceETHData, allowanceData])
    useEffect(() => {
        if (bnbData)
            setTxHash(bnbData)
        if (ethData)
            setTxHash(ethData)
        if (usdtData)
            setTxHash(usdtData)
        if (usdtethData)
            setTxHash(usdtethData)
    }, [bnbData, usdtethData, usdtData, ethData])

    useEffect(() => {
        if (bnbSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (ETHSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (usdtETHSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (usdtSuccess)
            setTypeOfTx("Waiting for confirmation...")
        if (allowanceSuccessETH)
            setTypeOfTx("Waiting for confirmation...")
        if (allowanceSuccess)
            setTypeOfTx("Waiting for confirmation...")
    }, [bnbSuccess, ETHSuccess, usdtETHSuccess, usdtSuccess, allowanceSuccessETH, allowanceSuccess])
    useEffect(() => {
        let x = Number(totalContribution) / 1000000
        let y = Number(totalContributionETH) / 1000000
        setTotalContributions(x + y + 257454)
        if (x + y < 0)
            setPercentage(((x + y + 257454) / 500000 * 100))
        else
            setPercentage(((x + y + 257454) / 500000 * 100))
        setTotalParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
        setParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
    }, [isContriError, isContriError2])

    useEffect(() => {
        if (totalParticipants === 0 && totalContributions === 0) {
            let x = Number(totalContribution) / 1000000
            let y = Number(totalContributionETH) / 1000000
            setTotalContributions(x + y + 257454)
            if (x + y < 0)
                setPercentage(((x + y + 257454) / 500000 * 100))
            else
                setPercentage(((x + y + 257454) / 500000 * 100))
            setTotalParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
            setParticipants(Number(totalParticipant) + Number(totalParticipantETH) + 279)
        }
    }, [totalContributions, totalParticipants])
    useEffect(() => { setPerc(Number(percentage)) }, [percentage])
    useEffect(() => {
        if (bnbError) {
            setLoadingStatus(false)
        }
        if (ETHError)
            setLoadingStatus(false)
        if (usdtError) {
            setLoadingStatus(false)
        }
        if (usdtEthError)
            setLoadingStatus(false)
        if (allowanceUSDTETHError)
            setLoadingStatus(false)
        if (allowanceUSDTerror) {
            setLoadingStatus(false)
        }
    }, [bnbError, ETHError, usdtError, usdtEthError, allowanceUSDTETHError, allowanceUSDTerror])

    useEffect(() => {
        if (allowanceSuccess) {
            setBuyNowBtn(t("BUY $DCARS"))
        }
    }, [allowanceSuccess])
    useEffect(() => {
        if (allowanceSuccessETH)
            setBuyNowBtn(t("BUY $DCARS"))
    }, [allowanceSuccessETH])
    useEffect(() => {
        const xallowance = Number(allowance);
        const yallowance = Number(allowanceETH)
        setCurrentAllowance(xallowance)
        setCurrentAllowanceETH(yallowance)

    }, [successAllowance, successAllowanceETH])


    useEffect(() => {
        (async () => {
            if (chains) {
                let ref = getURLParams().ref
                if (ref === undefined)
                    ref = "0x0000000000000000000000000000000000000000"
                else
                    setCoupon("ref")
                setReferral(ref)
                if (chains.name === 'Ethereum') {
                    setTokenChoice('ETH')
                    setBuyNowBtn(t("BUY $DCARS"))

                    if (ethStatus === "success") {
                        if (ethBalance.formatted !== "0") {
                            setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                            setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)
                        }
                        else {
                            setUserAmount(0)
                            setUserUSD(0)
                        }
                    }
                }
                else if (chains.name === 'BNB Smart Chain') {
                    setTokenChoice('BNB')
                    setBuyNowBtn(t("BUY $DCARS"))
                    if (status === "success") {
                        if (bnbBalance?.formatted !== "0") {
                            setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                            setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                        } else {
                            setUserAmount(0)
                            setUserUSD(0)
                        }
                    }
                }
            }
        }
        )()
    }, [])

    const getEthPrice = useCallback(async () => {
        let a = 0;
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')

            .then(function (response) {
                // handle success
                setEthPrice(Number(response.data.USD))
                // await axios.get('https://min-api.crypt
                a = response.data.USD
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
            });
        return Number(a);
    }, [ethPrice])


    const getBNBPrice = useCallback(async () => {
        let a = 0;
        // await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD')

            .then(function (response) {
                // handle success
                setBNBPrice(Number(response.data.USD));
                a = response.data.USD
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
            });
        return Number(a);
    }, [bnbPrice])


    useEffect(() => {
        (async () => {
            if (chains?.name === 'Ethereum') {

                if (ethStatus === "success") {
                    if (ethBalance.formatted !== "0") {
                        setUserAmount(Number(ethBalance?.formatted).toFixed(6) - 0.005)
                        setUserUSD(Number(ethBalance?.formatted).toFixed(6) * await getEthPrice() * currentDcarsPrice)

                    }
                    else {
                        setUserAmount(0)
                        setUserUSD(0)
                    }
                }
            }
            else if (chains?.name === 'BNB Smart Chain') {

                if (status === "success") {
                    if (bnbBalance?.formatted !== "0") {
                        setUserAmount(Number(bnbBalance?.formatted).toFixed(6) - 0.0015)
                        setUserUSD(Number(bnbBalance?.formatted).toFixed(6) * await getBNBPrice() * currentDcarsPrice)
                    } else {
                        setUserAmount(0)
                        setUserUSD(0)
                    }
                }
            }
        }
        )()
    }, [status, ethStatus])

    const [isIframeVisible, setIframeVisible] = useState(false);
    const handleClicsk = () => {
        setIframeVisible(true);
    };
    var params = "menubar=no,toolbar=no,status=no,width=570,height=570"; // for window
    const [shareLink, setShareLink] = useState("")
    function ShareToFaceBook() {
        let shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${shareLink}`;
        window.open(shareUrl, "NewWindow", params);
    }
    function ShareToTwitter() {
        let Shareurl = `https://twitter.com/intent/tweet?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToTG() {
        let Shareurl = `https://telegram.me/share/url?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToInsta() {
        let Shareurl = `https://www.instagram.com/?url=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToWA() {
        let Shareurl = `whatsapp://send?text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }


    const shareUrl = "https://dreamcars.co";
    const title = "Dream Cars";
    const handleCloseSpin = () => {
        setIsOPenSpin(false)
    }

    useEffect(() => {
        // Dynamically load the Wistia player script
        const wistiaScript = document.createElement('script');
        wistiaScript.src = 'https://fast.wistia.com/player.js';
        wistiaScript.async = true;
        document.body.appendChild(wistiaScript);

        // Optionally load additional scripts if needed
        const additionalScript = document.createElement('script');
        additionalScript.src = 'LINK'; // Replace 'LINK' with the actual URL for the other script
        additionalScript.async = true;
        additionalScript.type = 'module';
        document.body.appendChild(additionalScript);

        return () => {
            // Clean up scripts if the component unmounts
            document.body.removeChild(wistiaScript);
            document.body.removeChild(additionalScript);
        };
    }, []);

    return (
        <div id="hero" className="backgroundgr pt-[3.5rem] xs:pt-[4rem]">
            <div className=" space-y-4 py-[4rem] xs:py-[2.2rem] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[90%] max-w-[1276px] mx-auto ">
                <div className="flex justify-between  lg:space-y-4 xs:space-y-3 sm:space-y-3 md:space-y-3 lg:block xs:block md:block sm:block">
                    <div className={userAddress ? "w-[50%] xl:w-[50%] flex flex-col space-y-[40px] xs:space-y-[1px] lg:w-[100%]  xs:w-[100%] sm:w-[100%] md:w-[100%]" : "w-[50%] xl:w-[50%] flex flex-col justify-between lg:w-[100%]  xs:w-[100%] sm:w-[100%] md:w-[100%]"}>
                        <div className={userAddress ? "xs:pt-[0rem]" : "space-y-[12px] xs:pt-[0rem]"}>
                            <h5 className="2xl:text-[17.5px] xl:text-[17.5px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[12px] leading-[130%] font-[400] font-[Lato] text-white ">
                                {t("ProductDetails.para1")}
                            </h5>
                            <h3 className="text-[40px] my-6 xs:my-4 xs:text-[24px] leading-[1.3] capitalize font-[900] font-[Lato] text-[#fff] ">
                                {" "}
                                {t("ProductDetails.title1")}
                            </h3>
                            <h5 className="2xl:text-[22px] xl:text-[22px] lg:text-[22px] md:text-[22px] sm:text-[20px]  font-[400] leading-[120%] text-white ">
                                {t("ProductDetails.para2")}
                            </h5>
                        </div>
                        <div className=" flex items-center py-[0px] justify-start space-x-[1rem] mt-5 xs:my-5  sm:my-5">
                            <a href="https://github.com/solidproof/projects/blob/main/2024/Dreamcars/EVM_Audit_SolidProof_Dreamcars.pdf" target="_blank" className="2xl:text-[18px]  xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[15px]  text-[15px]  text-white underline font-[Lato]">
                                {t("ProductDetails.para3last")}
                            </a>
                            <img className="h-[35px] xs:h-[33px]" src={Solid} alt="" />
                        </div>
                        <div className=" xs:pb-[0.8rem] space-y-[1rem] xs:mb-4">
                            <h3 className="2xl:text-[24px] xl:text-[24px] lg:text-[24px] md:text-[24px] sm:text-[24px] text-[16px] text-[#fff] font-[900] font-[700] mb-5 mt-2">
                                {t("ProductDetails.watchpara")}
                            </h3>

                            <style>
                                {`
                                                wistia-player[media-id='8dzagr2loo']:not(:defined) {
                                                    background: center / contain no-repeat url(`+ { thumb } + `);
                                                    filter: blur(5px);
                                                    padding-top: 56.25%;
                                                }
                                                `}
                            </style>

                            <div className="wistia-player-container">
                                <wistia-player media-id="8dzagr2loo"></wistia-player>
                            </div>
                            {/* <div
                                className="relative bg-filterved rounded-[10px] bgposter min-h-[360px] xs:min-h-[160px] w-[100%] xs:h-[194px] sm:h-[330px] md:h-[420px] overflow-hidden"
                                style={{ cursor: 'pointer' }}
                                onClick={handleClicsk}
                            >
                                {isIframeVisible ? (
                                    <>

                                    </>

                                ) : (
                                    <img
                                        src={thumb}
                                        alt="Video Thumbnail"
                                        className="w-full h-full object-cover rounded-[10px]"
                                    />
                                )}
                            </div> */}
                        </div>
                    </div>
                    <div className="w-[45%] xl:w-[45%] lg:w-[65%] lg:mx-auto flex flex-col justify-start  xs:w-[100%] sm:w-[100%] md:w-[100%] z-[2]">
                        <div className="backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask rounded-[34px] space-y-[10px]">
                            <div className=" pt-[18px] pb-[14px] xs:pb-[20px]   2xl:px-[52px] xl:px-[45px] lg:px-[32px] md:px-[52px] sm:px-[52px] px-[10px]  space-y-4">
                                <div>
                                    <h3 className="2xl:text-[24px] xl:text-[24px] lg:text-[26px] md:text-[26px] sm:text-[28px] text-[18px] font-[700] text-center  2xl:leading-[120%] xl:leading-[120%]  lg:leading-[120%] md:leading-[120%] sm:leading-[120%] text-white">
                                        {t("ProductDetails.card-title")}
                                    </h3>
                                </div>
                                <div className="bgposter ">
                                    <div className="bg-[#212121] max-w-[703px] rounded-t-[13px] px-8 xs:px-[15px]  sm:px-16 md:px-16 py-6 xs:pt-5 sm:pt-5 md:pt-8 ">
                                        <h3 className="2xl:text-[42px] xl:text-[42px] lg:text-[34px] md:text-[32px] sm:text-[36px] xs:text-[36px]  font-[700] text-center text-[#fff]">${totalContributions ? totalContributions.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "366,304.75"}
                                        </h3>
                                        <div className="flex items-center justify-between space-x-2 ">
                                            <h5 className=" text-center font-[Lato] 2xl:text-[12px] xl:text-[12px] lg:text-[12px] md:text-[13px] sm:text-[13px] xs:text-[12px]  text-[#929292]">
                                                {
                                                    percentage ? percentage.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 73.26
                                                }%  {t("of minimum goal raised")}
                                            </h5>
                                            <span className="relative !mr-[-5px]">
                                                <button
                                                    onMouseEnter={() => setPop1(true)}
                                                    onMouseOut={() => setPop1(false)}
                                                    type="button"
                                                    className={`cursor-pointer `}
                                                >
                                                    <img
                                                        src={Iicon}
                                                        alt=""
                                                        style={{ marginBottom: "-8px", width: "20px" }}
                                                    />
                                                </button>
                                                <div
                                                    className={`${pop1
                                                        ? "absolute z-10 w-[220px] right-28  2xl:right-[0px] xl:right-[0px] lg:right-[0px] 2xl:top-[-104px] xl:top-[-104px] lg:top-[-104px] md:right-[0px] md:top-[-56px] sm:right-[0px] sm:top-[-56px] xs:right-[0px] top-[-92px] text-center py-2 px-3 bg-[#ffffff]  rounded-lg text-[#22222] text-sm"
                                                        : "hidden"
                                                        }`}
                                                >
                                                    <span className="text-black font-[Lato]">
                                                        This is the lowest price available for $DCARS. The price will increase at the end of each stage.
                                                    </span>
                                                    <div className="absolute -z-99 w-5 h-5 -bottom-1 sm:right-[10px] xs:right-[10px] right-[10px] bg-[#ffffff] rotate-45"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <div>
                                            <div className="z-1 w-full bg-gray-200 rounded-full h-2.5 ">
                                                {/* <div className={`bgcolor h-2.5 rounded-full w-[` + (percentage ? Number(percentage.toFixed(0)) : 72.92) + `%]`}></div> */}
                                                {/* {percentage ? <div className="bgcolor h-2.5 rounded-full w-[{62%}]"></div>
                                                    : <div className="bgcolor h-2.5 rounded-full w-[62%]"></div>} */}
                                                <div className="bgcolor h-2.5 rounded-full " style={{
                                                    width: percentage ? percentage + `%` : 73.26 + `%`
                                                }}></div>
                                            </div>
                                            <p className="text-end text-[12px] pt-[3px]  text-[#929292]">
                                                $500,000
                                            </p>
                                        </div>
                                        <div>
                                            <h5 className="font-[Lato] text-center text-[14px] xs:text-[18px] font-[500] text-white">
                                                {totalParticipants ? totalParticipants : "584"} {t("Contributions")}
                                            </h5>
                                            <div></div>
                                            <h5 className="font-[Lato] text-center text-[11px] xs:text-[14px]  font-[500] space-x-1 text-[#929292]">
                                                Launching Price = $0.03
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="bg-[#3F3F3F] rounded-b-[10px] p-4 xs:pb-5 sm:pb-5 md:pb-8  xs:px-[15px] sm:px-14 md:px-10 ">
                                        <div className="flex justify-between"></div>
                                        <div className="py-1 space-y-4 xs:space-y-3">
                                            {userAddress ?
                                                <div className="flex backdrop-blur-md bg-[#ffffff40] border-r border-t  max-w-[362px] w-[100%] mx-auto py-[5px]  items-center justify-center">
                                                    <p className="text-[14px] text-[#fff] text-center font-[700] ">
                                                        You Own = {finalUserTokens ? finalUserTokens.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0"} DCARS <br></br><span className="underline text-[10px]" onClick={(e) => {
                                                            handleClose(e)
                                                        }}>More Info</span>
                                                    </p>
                                                </div>
                                                : ""
                                            }
                                            <div className="flex backdrop-blur-md bg-[#ffffff40] border-r border-t rounded-[60px] max-w-[362px] w-[100%] mx-auto py-[7px]  items-center justify-center">
                                                <p className="text-[14px] text-[#fff] text-center font-[700]">
                                                    Current Price = $0.0105{" "}
                                                    {/* <span className="text-[#19D548] font-[700]">(+185.71%)</span> */}
                                                </p>
                                            </div>
                                            <div className="2xl:flex justify-between xl:flex lg:flex md:flex flex sm:flex 2xl:space-x-0 xl:space-x-0 lg:space-x-0 md:space-x-0 sm:space-x-0 space-x-1">
                                                {buttons.map((button) => (
                                                    <button
                                                        key={button.id}
                                                        onClick={() => handleClickButton(button.id)}
                                                        className={`flex items-center xs:h-[40px] sm:h-[40px] xs:w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] justify-center xs:text-[14px] rounded-[14px] 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-0 xs:py-1 py-[8px] backdrop-blur transition-all duration-300 ${activeButton === button.id
                                                            ? 'bg-white text-black shadow-inner'
                                                            : 'bg-[#ffffff17] text-white'
                                                            }`}
                                                    >
                                                        <div className="flex flex-col items-center justify-center w-[60px]">
                                                            <img src={button.imgSrc} className="h-[17px]" alt={button.label} />
                                                            <span className="mt-0 text-[10px] font-[Lato] font-[800] mt-[6px] w-[30px]">{button.label}</span>
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                            <div className="flex relative z-[9]">
                                                <div className="w-[100%] space-y-[5px]">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#D0D0D0] font-[Lato] text-[12px] xs:text-[14px]"
                                                    >
                                                        Amount in {tokenChoice} you pay
                                                    </label>
                                                    <div className="flex bg-[#fff] rounded-[6px]">
                                                        <input
                                                            type="text"
                                                            className="bg-[#fff] w-[80%] p-2 rounded-l-[6px] text-[#000] text-[20px] h-[42px] font-[Lato] w-[100%] outline-none rounded-l "
                                                            placeholder="100" value={userAmount ? userAmount : ""} onChange={(e) => {
                                                                handleUserInput(e)
                                                            }}
                                                        />
                                                        <div className="relative z-[9] inline-block text-left">
                                                            <button onClick={handleButtonClick} className="flex text-[#fff] text-[12px] font-[400] items-center justify-between p-[7px] !pr-[10px] 2xl:w-[130px] xl:w-[130px] lg:w-[130px] md:w-[130px] sm:w-[130px] w-[105px] h-[42px] border rounded-r-[6px] bg-[#D1D5DB]">
                                                                <div className="flex items-center space-x-[8px]">
                                                                    <img
                                                                        src={selectedOption.image}
                                                                        alt={selectedOption.label}
                                                                        className="max-h-[32px] min-h-[31px]"
                                                                    />
                                                                    <span className="text-[#000] leading-[100%] pt-1">
                                                                        <span>{selectedOption.label}</span>
                                                                    </span>
                                                                </div>
                                                                <img
                                                                    src={downarw}
                                                                    className=""
                                                                    alt="dropdown arrow"
                                                                />
                                                            </button>
                                                            {showDropdown && (
                                                                <div className="absolute right-0 mt-2 w-[130px] backdrop-blur-md bg-[#646464e6] rounded-[12px] shadow-lg">
                                                                    {options.map((option) => (

                                                                        <div
                                                                            key={option.id}
                                                                            onClick={() => handleOptionClick(option)}
                                                                            className="cursor-pointer flex  items-center p-[7px] space-x-[8px] hover:bg-[#737373] rounded-[5px]"
                                                                        >
                                                                            <img
                                                                                src={option.image}
                                                                                alt={option.label}
                                                                                className="max-h-[25px] min-h-[29px]"
                                                                            />
                                                                            <span className="text-[#fff]">{option.label}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex relative z-[1]">
                                                <div className="w-[100%] space-y-[5px]">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#D0D0D0] font-[Lato] text-[12px] xs:text-[14px]"
                                                    >
                                                        {t("ProductDetails.card-body-amount-receive")}{" "}

                                                        <a href={"#membership"} className="" >
                                                            <span className="text-[#FFD02F] font-bold underline">
                                                                {userUSD / currentDcarsPrice >= 1000 && userUSD / currentDcarsPrice < 5000 ? "Mercedes NFT" :
                                                                    userUSD / currentDcarsPrice >= 5000 && userUSD / currentDcarsPrice < 10000 ? "Porsche NFT" :
                                                                        userUSD / currentDcarsPrice >= 10000 && userUSD / currentDcarsPrice < 25000 ? "Bentley NFT" :
                                                                            userUSD / currentDcarsPrice >= 21000 && userUSD / currentDcarsPrice < 100000 ? "Ferrari NFT" :
                                                                                userUSD / currentDcarsPrice >= 100000 ? "Lamborghini NFT" : ""
                                                                }
                                                            </span>
                                                        </a>
                                                    </label>
                                                    <div className="flex items-center justify-between bg-[#fff] rounded-[6px]">
                                                        <input
                                                            type="text"
                                                            className="text-[#000] p-2 text-[20px] h-[42px] bg-[#fff] font-[Lato] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[70%] sm:w-[70%] w-[70%] outline-none rounded-l-[6px] "
                                                            placeholder="58.823" value={userUSD ? userUSD.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) : ""} onChange={(e) => {
                                                                handleDcarsInput(e)
                                                            }}
                                                        />
                                                        <button className="flex text-[12px] font-[400] items-center px-2 space-x-[8px] 2xl:w-[130px] xl:w-[130px] lg:w-[130px] md:w-[130px] sm:w-[130px] w-[105px] h-[42px] text-[#000] rounded-r-[6px] bg-[#D1D5DB]">
                                                            <img
                                                                src={Loinp}
                                                                className="h-[32px] xs:w-[35px] xs:h-[35px] mr-2 xs:mr-1"
                                                                alt=""
                                                            />
                                                            $DCARS
                                                        </button></div>
                                                </div>
                                            </div>
                                            <div className="w-[100%] relative z-[1] xs:pt-[20px]">
                                                <ConnectButton.Custom>
                                                    {({
                                                        account,
                                                        chain,
                                                        authenticationStatus,
                                                        mounted,
                                                    }) => {
                                                        const ready = mounted && authenticationStatus !== 'loading';
                                                        const connected =
                                                            ready &&
                                                            account &&
                                                            chain &&
                                                            (!authenticationStatus ||
                                                                authenticationStatus === 'authenticated');
                                                        if (chain)
                                                            setCurrentChain(chain.name)
                                                        return (
                                                            <div
                                                                {...(!ready && {
                                                                    'aria-hidden': true,
                                                                    'style': {
                                                                        opacity: 0,
                                                                        pointerEvents: 'none',
                                                                        userSelect: 'none',
                                                                    },
                                                                })}
                                                            >
                                                                {(() => {
                                                                    if (!connected) {
                                                                        return (

                                                                            <button className='bgcolor font-[700] font-[Lato] w-[100%] h-[42px] rounded-[5px] text-[18px]'
                                                                                onClick={openConnectModal} > {t('ProductDetails.card-body-walletBtn')}</button>
                                                                        );
                                                                    }

                                                                    if (chain.unsupported) {
                                                                        return (
                                                                            <button onClick={openChainModal} type="button">
                                                                                Wrong network
                                                                            </button>
                                                                        );
                                                                    }

                                                                    setUserAddress(account.address)
                                                                    return (
                                                                        <div>
                                                                            <button
                                                                                className="bgcolor font-[700] font-[Lato] w-[100%] h-[42px] rounded-[5px] text-[16px]"
                                                                                type="button" onClick={buyToken}>
                                                                                {buynowbtn}
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                })()}
                                                            </div>
                                                        );
                                                    }}
                                                </ConnectButton.Custom>
                                            </div>
                                            {show && (
                                                <div className="flex relative z-[1]">
                                                    <input
                                                        type="text"
                                                        className="h-[40px] w-[100%] px-2 outline-none rounded-l"
                                                        placeholder="Bonus code" onChange={(e) => {
                                                            setCoupon(e.target.value.toLowerCase())
                                                        }}
                                                    />
                                                    <button className="flex h-[40px] items-center text-white rounded-r space-y-3 px-5   bg-black" onClick={handleCoupon}>
                                                        <p className="text-[12px] text-whites font-[700]">
                                                            {t("ProductDetails.card-body-Bonus-code-apply")}
                                                        </p>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flex justify-between relative z-[1] space-x-[15px] xs:pt-[0.5rem]">
                                        <a
                                            href="#how-to-buy"
                                            className="flex cursor-pointer border border-[#fff] text-[14px] xs:text-[11px] font-[500]  leading-[21px] flex w-[278px] h-[30px] items-center justify-center text-white font-[Lato] rounded-[10px] bg-[#3F3F3F]"
                                        >
                                            {" "}
                                            <img
                                                className="pr-1 w-[22px] h-[22px]"
                                                src={que}
                                                alt=""
                                            />{" "}
                                            {t("ProductDetails.card-body-buyBtn")}
                                        </a>
                                        {!userAddress ?
                                            <button
                                                onClick={openConnectModal}
                                                className="cursor-pointer border border-[#fff] text-[14px] xs:text-[11px] font-[500] font-[Lato] leading-[21px] flex w-[278px] h-[30px] items-center justify-center text-white  rounded-[10px] bg-[#3F3F3F]"
                                            >
                                                {" "}
                                                <img
                                                    className="pr-1 w-[22px] h-[22px]"
                                                    src={refe}
                                                    alt=""
                                                />
                                                {t("ProductDetails.card-body-referralBtn")}
                                            </button> :

                                            <button
                                                onClick={handleCopy}
                                                className="cursor-pointer border border-[#fff] text-[14px] xs:text-[11px] font-[500] font-[Lato] leading-[21px] flex w-[278px] h-[30px] items-center justify-center text-white  rounded-[10px] bg-[#3F3F3F]"
                                            >
                                                {" "}
                                                <img
                                                    className="pr-1 w-[22px] h-[22px]"
                                                    src={refe}
                                                    alt=""
                                                />
                                                {t("ProductDetails.card-body-referralBtn")}
                                            </button>}
                                    </div>

                                    {!show && (
                                        <div className="pt-[2rem] relative z-[1]">
                                            <p
                                                onClick={handelBonus}
                                                className="cursor-pointer text-[#DDD] underline text-center  text-[13px] font-[500] leading-[7px]"
                                            >
                                                {t("ProductDetails.card-body-Bonus-code")}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="relative z-[1] pt-[10px] pb-[4px]">
                                    <p className="text-[15px] text-center font-[400] text-[#fff] ">Share Dreamcars with your Friends</p>
                                </div>
                                <div className="flex relative z-[1] space-x-3 justify-center">

                                    <img className="h-[35px]  xs:h-[20px] cursor-pointer w-[35px] xs:w-[20px] hidden xs:block" src={sharelogo} alt="" onClick={handleShare} />
                                    <a href="" target="" rel="noopener noreferrer" onClick={(e) => {
                                        e.preventDefault();
                                        copyUrlToClipboard();
                                    }}>
                                        <img
                                            className="h-[26px] xs:h-[20px] cursor-pointer w-[26px] xs:w-[20px]"
                                            src={Link1}
                                            alt=""
                                        />
                                    </a>
                                    <a href="https://twitter.com/dreamcars_bsc" target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="h-[26px]  xs:h-[20px] cursor-pointer w-[26px] xs:w-[20px]"
                                            src={Link2}
                                            alt=""
                                        /></a>
                                    <a href="https://t.me/Dreamcars_bsc" target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="h-[26px]  xs:h-[20px] cursor-pointer w-[26px] xs:w-[20px]"
                                            src={Link3}
                                            alt=""
                                        />
                                    </a>
                                    <a href="https://www.instagram.com/dreamcars_bsc/" target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="h-[26px]  xs:h-[20px] cursor-pointer w-[26px] xs:w-[20px]"
                                            src={Link4}
                                            alt=""
                                        />
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductDetailsSection;

